import { Icon } from '@chakra-ui/react';
import { useUser } from '@lib/user';
import { ButtonTextIcon, ButtonTextProps } from '@cmp/common/ButtonTextIcon';
import { MdPassword } from 'react-icons/md';

export interface ExhangeCodeButtonProps extends Omit<ButtonTextProps, 'icon' | 'children'> {}

export const ExhangeCodeButton: React.FC<ExhangeCodeButtonProps> = ({ ...props }) => {
  const { user /*, loading*/ } = useUser();
  const navigateCodeButton = user === null ? '/registro' : '#exchange-menu';

  return (
    <ButtonTextIcon
      // isLoading={loading}
      href={navigateCodeButton}
      bgColor="#1E22AA"
      borderRadius="100px"
      p="4px 4px 4px 12px"
      gap="6px"
      paddingIcon="10px"
      bgIcon="#FFF"
      colorText="#FFF"
      fontSizeText={{ base: '12px', md: '12px' }}
      _hover={{ bg: '#0E129A' }}
      _active={{ bg: '#00028A' }}
      icon={<Icon as={MdPassword} color={'#FF6900'} boxSize={'18px'} />}
      {...props}
    >
      Inscribe tu código
    </ButtonTextIcon>
  );
};

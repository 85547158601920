import { Outlet, useLocation, ScrollRestoration } from 'react-router-dom';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from '@lib/auth';
import { UserProvider } from '@lib/userProvider';
import theme from '@cmp/theme';
import Fonts from '@cmp/fonts';
import { useNavigation } from 'react-router-dom';
import Loader from '@cmp/loader';
import { ButtonWhatssap } from '@cmp/buttons/buttonWhatssap';
import { WpProvider } from '@cmp/buttons/buttonWhatsappProvider';
import { ExhangeCodeButton } from '@cmp/exchangeCodeButton';

import '@/App.css';

export const App = () => {
  const location = useLocation();
  const navigation = useNavigation();
  const isLoading = navigation.state === 'loading';

  //let hasBlur = location.pathname === '/home' || location.pathname === '/';
  const hasBlur = false;

  return (
    <>
      <ScrollRestoration getKey={(location) => location.pathname} />
      <ChakraProvider theme={theme}>
        <Fonts />
        <AuthProvider>
          <UserProvider>
            <WpProvider>
              <Outlet />

              {!isLoading && location.pathname !== '/canjear-cupon' && (
                <>
                  <Box position={'relative'}>
                    <ButtonWhatssap
                      zIndex={10}
                      position={'fixed'}
                      right={{ base: '15px', md: '60px' }}
                      bottom={{ base: 'calc(4vh + 35px)', md: '10%' }}
                    />
                    <Box
                      background={hasBlur ? 'rgba(255, 255, 255, 0.20)' : 'unset'}
                      backdropFilter={hasBlur ? 'blur(15px)' : 'unset'}
                      zIndex={location.pathname === '/home' ? 2 : 10}
                      position={'fixed'}
                      w={'100vw'}
                      bottom={'0%'}
                      pointerEvents={'none'}
                    >
                      <Box
                        w={'100%'}
                        px={{ base: '15px', md: '150px' }}
                        display={'flex'}
                        justifyContent={'flex-end'}
                        py={'12px'}
                        pointerEvents={'none'}
                      >
                        <ExhangeCodeButton pointerEvents={'auto'} />
                      </Box>
                    </Box>
                  </Box>
                </>
              )}

              {isLoading && <Loader m={0} zIndex={1001} position={'fixed'} top={0} left={0} w={'100vw'} h={'100vh'} />}
            </WpProvider>
          </UserProvider>
        </AuthProvider>
      </ChakraProvider>
    </>
  );
};

import { UseFormSetError, FieldValues } from 'react-hook-form';
import { GraphQLFormattedError } from 'graphql';

/* eslint-disable @typescript-eslint/no-explicit-any */

export class HttpError extends Error {
  public constructor(
    message: string,
    public readonly response: Response,
  ) {
    super(message);
  }
}

export const CSRFError = new Error(
  'La sesión ha expirado, intenta refrescar el navegador o iniciar la sesión de nuevo',
);

export const internalServerErrorMessage =
  'Error interno del servidor, intenta refrescar el navegador. En caso de que el error continue intenta de nuevo más tarde';

export class InternalServerError extends HttpError {
  public constructor(response: Response) {
    super(internalServerErrorMessage, response);
  }
}

export interface GraphQLError {
  message: string;
  field?: string;
  extensions?: Record<string, string | number>;
}

export class RequestError extends Error {
  public readonly errors: GraphQLError[];

  constructor(message: string, errors: ReadonlyArray<GraphQLFormattedError> | Record<string, string[]>) {
    super(message);

    if (Array.isArray(errors)) {
      this.errors = errors;
      return;
    }

    const newErrors = [];

    for (const field in errors) {
      newErrors.push({
        field,
        // @ts-expect-error This will be a string
        message: errors[field][0],
      });
    }

    this.errors = newErrors;
  }

  first(): GraphQLError | null {
    return this.errors?.[0] || null;
  }
}

export function mergeRequestErrorAndUseForm<T extends FieldValues>(options: {
  setError: UseFormSetError<T>;
  requestError: RequestError;
  showFirst?: (message: string) => void;
}) {
  const firstError = options.requestError.errors[0];

  if (!firstError.field) {
    options.showFirst?.(firstError.message);
  }

  for (const error of options.requestError.errors) {
    if (error.field) {
      options.setError(error.field as any, {
        message: error.message,
        type: 'value',
      });
    }
  }

  return;
}

export function hasErrorMessage(error: unknown): error is { message: string } {
  return !!error && typeof error === 'object' && 'message' in error && typeof error.message === 'string';
}

export const isNetworkError = (error: unknown): error is { networkError: { statusCode: number } } => {
  return (
    !!error &&
    typeof error === 'object' &&
    'networkError' in error &&
    typeof error.networkError === 'object' &&
    error.networkError !== null &&
    'statusCode' in error.networkError &&
    typeof error.networkError.statusCode === 'number'
  );
};

export const isGraphQLError = (error: unknown): error is { graphQLErrors: { message: string }[] } => {
  return (
    !!error &&
    typeof error === 'object' &&
    'graphQLErrors' in error &&
    typeof error.graphQLErrors === 'object' &&
    Array.isArray(error.graphQLErrors)
  );
};

export const checkStatusCode = (error: unknown | undefined, statusCode: number): boolean => {
  return isNetworkError(error) && error?.networkError?.statusCode === statusCode;
};

export function getErrorFromExtension(
  errors: ReadonlyArray<GraphQLFormattedError> | null | undefined,
  extension: string,
): string | null {
  if (!errors) {
    return null;
  }

  for (const err of errors) {
    // @ts-expect-error Won't check every single field in this extension...
    const value = err.extensions?.validation?.[extension]?.[0];
    if (value && typeof value === 'string') {
      return value;
    }
  }

  return null;
}

/* eslint-enable @typescript-eslint/no-explicit-any */

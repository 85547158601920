import React from 'react';
import { Link } from 'react-router-dom';
import { Box, BoxProps, TextProps } from '@chakra-ui/react';
import { Text } from './Text';
import { IconBackground, IconBackgroundProps } from './IconBackground';

export interface ButtonTextProps extends BoxProps {
  href?: string;
  justifyContent?: string;
  borderRadius?: string;
  bgColor?: string;
  p?: string;
  gap?: string;
  paddingIcon?: string | number;
  bgIcon?: string;
  colorText?: string;
  fontSizeText?: { base?: string; md?: string; lg?: string };
  weightText?: string;
  icon: React.ReactNode;
  orderText?: number;
  orderIcon?: number;
  children: React.ReactNode;
  _text?: TextProps;
  _icon?: IconBackgroundProps;
}

export const ButtonTextIcon: React.FC<ButtonTextProps> = ({
  borderRadius,
  bgColor,
  p,
  gap,
  bgIcon,
  colorText,
  fontSizeText,
  weightText,
  paddingIcon,
  icon,
  orderText,
  orderIcon,
  href,
  justifyContent = 'space-between',
  children,
  _text = {},
  _icon = {},
  ...props
}) => {
  return (
    <Box
      p={p}
      gap={gap}
      as={Link}
      to={href}
      display={'flex'}
      justifyContent={justifyContent}
      alignItems={'center'}
      borderRadius={borderRadius}
      backgroundColor={bgColor}
      {...props}
    >
      <Box order={orderText} display={'flex'}>
        <Text as={'span'} color={colorText} fontSize={fontSizeText} fontWeight={weightText} {..._text}>
          {children}
        </Text>
      </Box>
      <Box order={orderIcon}>
        <IconBackground padding={paddingIcon} background={bgIcon} {..._icon}>
          {icon}
        </IconBackground>
      </Box>
    </Box>
  );
};

import { Global, css } from '@emotion/react';

import clashGrotesBoldttf from '@ast/fonts/ClashGrotes/ClashGrotesk-Bold.ttf';
import clashGrotesBoldwoff from '@ast/fonts/ClashGrotes/ClashGrotesk-Bold.woff';
import clashGrotesBoldeot from '@ast/fonts/ClashGrotes/ClashGrotesk-Bold.eot';

import clashGrotesRegularttf from '@ast/fonts/ClashGrotes/ClashGrotesk-Regular.ttf';
import clashGrotesRegularwoff from '@ast/fonts/ClashGrotes/ClashGrotesk-Regular.woff';
import clashGrotesRegulareot from '@ast/fonts/ClashGrotes/ClashGrotesk-Regular.eot';

import clashGrotesMediumttf from '@ast/fonts/ClashGrotes/ClashGrotesk-Medium.ttf';
import clashGrotesMediumwoff from '@ast/fonts/ClashGrotes/ClashGrotesk-Medium.woff';
import clashGrotesMediumeot from '@ast/fonts/ClashGrotes/ClashGrotesk-Medium.eot';

import '@fontsource/rethink-sans/latin-400.css';
import '@fontsource/rethink-sans/latin-500.css';
import '@fontsource/rethink-sans/latin-600.css';
import '@fontsource/rethink-sans/latin-700.css';
import '@fontsource/rethink-sans/latin-800.css';

const Fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'ClashGrotesk';
        src:
          url('${clashGrotesRegularttf}') format('ttf'),
          url('${clashGrotesRegularwoff}') format('woff'),
          url('${clashGrotesRegulareot}') format('truetype');
        font-weight: 400;
        font-display: swap;
        font-style: normal;
      }
      @font-face {
        font-family: 'ClashGrotesk';
        src:
          url('${clashGrotesMediumttf}') format('ttf'),
          url('${clashGrotesMediumwoff}') format('woff'),
          url('${clashGrotesMediumeot}') format('truetype');
        font-weight: 500;
        font-display: swap;
        font-style: normal;
      }
      @font-face {
        font-family: 'ClashGrotesk';
        src:
          url('${clashGrotesBoldttf}') format('ttf'),
          url('${clashGrotesBoldwoff}') format('woff'),
          url('${clashGrotesBoldeot}') format('truetype');
        font-weight: 700;
        font-display: swap;
        font-style: normal;
      }
    `}
  />
);

export default Fonts;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { setupManager } from '@lib/session';
import { newApolloClient, setClient } from '@lib/apollo';
import { addListeners as addWebPushListeners } from '@hks/web-push';
import { createRouter } from './router';
import { register as registerServiceWorker } from './sw-register';
import * as Sentry from '@sentry/react';

import '@/index.css';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 0.05,
  tracePropagationTargets: ['localhost', /^https:\/\/www.ganaconprimax.com\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

registerServiceWorker();

(async () => {
  const sessionManager = setupManager();
  await sessionManager.loadToken();

  const client = newApolloClient({ fetch: sessionManager.fetch.bind(sessionManager) });
  setClient(client);
  addWebPushListeners(sessionManager, client);

  const router = createRouter();

  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>,
  );
})();

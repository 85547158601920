import { defineStyleConfig, extendTheme, ButtonProps } from '@chakra-ui/react';

const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: 'full',
    _focus: { outline: '0px' },
  },
  variants: {
    // ghost: ,
    underLineActive: {
      rounded: '0px',
      textColor: 'orange',
      borderBottomWidth: '2px',
      borderBottomColor: 'orange',
      fontSize: '16px',
      fontWeight: 700,
      _hover: {
        borderTopWidth: '0',
        borderLeftWidth: '0',
        borderRightWidth: '0',
        borderBottomColor: 'orange',
      },
    },

    ghost(props) {
      const result: ButtonProps = {
        border: '0px',
        color: '#656565',
        fontSize: '16px',
        fontWeight: '500',
      };

      if (props.colorScheme === 'orange') {
        result.color = '#FF6900';
        result.bg = 'white';
        result.borderColor = '#FF6900';
        result._hover = {
          bg: 'orange.50',
        };
      }

      return result;
    },

    // transparent outline
    tOutline() {
      return {
        color: 'white',
        borderColor: 'orange.500',
        colorScheme: 'orange',
        borderWidth: 1,
        borderStyle: 'solid',
        fontWeight: '400',
        _hover: {
          bg: 'transparent',
          color: 'gray.200',
        },
        _active: {
          bg: 'transparent',
          color: 'gray.300',
        },
      };
    },

    outline(props) {
      const result: ButtonProps = {};

      if (props.colorScheme === 'blue') {
        result.color = 'blue.500';
        result.borderColor = 'blue.500';
      }

      return result;
    },

    solid: (props: ButtonProps) => {
      const overide: ButtonProps = {
        border: '0',
        fontWeight: '700',
        _active: {
          borderColor: 'transparent',
          fontWeight: '700',
        },
      };

      if (props.as === 'a' && props.colorScheme === 'orange') {
        overide._hover = {
          color: 'gray.100',
        };
        overide._active = {
          color: 'gray.200',
        };
      }

      if (props.isDisabled && props.colorScheme === 'blue') {
        overide._disabled = {
          bg: '#9A9A9A',
          _hover: {
            bg: '#9A9A9A',
          },
        };
      }

      return overide;
    },

    secondary: {
      textColor: '#656565',
      fontWeight: '500',
      color: 'white',
      _hover: {
        filter: 'sepia',
      },
    },
  },
  defaultProps: {
    variant: 'solid',
  },
});

const Text = defineStyleConfig({
  baseStyle: {
    fontFamily: `'ClashGrotesk'`,
    color: '#656565',
  },

  sizes: {
    sm: {
      fontWeight: '400',
    },
  },
});

const Heading = defineStyleConfig({
  baseStyle: {
    fontWeight: '700',
    fontFamily: `'ClashGrotesk'`,
    textColor: 'orange',
    fontStretch: 'ultra-condensed',
  },
});

const Input = defineStyleConfig({
  baseStyle: {
    field: {
      rounded: 'full',
      height: '48px',
      color: '#656565',
      focusBorderColor: 'red',
      _placeholder: {
        color: '#9B9B9B',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '24px',
      },
      _active: {
        borderColor: 'naranja !important',
        boxShadow: '0 0 0 1px naranja !important',
      },
      _hover: {
        borderColor: 'orange !important',
        backgroundColor: 'rgba(255, 105, 0, 0.05) !important',
        boxShadow: '0 0 0 1px orange !important',
      },
      _focus: {
        borderColor: 'azulPrimax !important',
        boxShadow: '0 0 0 1px azulPrimax !important',
      },
      _focusVisible: {
        borderColor: 'azulPrimax !important',
        boxShadow: '0 0 0 1px azulPrimax !important',
      },
    },
    size: '19px',
    lineHeight: '20px',
  },
});

const Select = defineStyleConfig({
  baseStyle: {
    field: {
      rounded: 'full',
      height: '48px',
      color: '#9B9B9B',
      fontSize: '14px',
      lineHeight: '24px',
      _placeholder: {
        color: '#9B9B9B',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '24px',
      },
    },
    size: '19px',
    lineHeight: '20px',
  },
});

const Link = defineStyleConfig({
  baseStyle: {
    fontSize: '14px',
    textDecor: 'underline',
    textColor: 'azulPrimax',
  },
  variants: {
    gray: {
      textDecor: 'none',
      textColor: '#7E8085',
      fontWeight: '300',
      _hover: { opacity: '0.65', textDecor: 'none', textColor: '#7E8085' },
    },
  },
});

const FormLabel = defineStyleConfig({
  baseStyle: {
    color: '#656565',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '16px',
    marginBottom: '12px',
  },
});

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
  backgroundColor: 'white',
  rounded: 'full',
};

const floatingLabel = {
  top: '8px',
  left: 2,
  zIndex: 2,
  position: 'absolute',
  backgroundColor: 'transparent',
  pointerEvents: 'none',
  mx: 3,
  px: 1,
  my: 2,
  transformOrigin: 'left top',
  color: '#656565',
  fontWeight: 400,
  fontSize: 'sm',
};

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    headings: `"ClashGrotesk", sans-serif`,
    content: `"Rethink Sans", sans-serif`,
  },
  components: {
    Button,
    Text,
    Link,
    Input,
    Select,
    FormLabel,
    Heading,
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            ['input:not(:placeholder-shown) + label, ' +
            '.chakra-input__group:has(input:not(:placeholder-shown)) + label, ' +
            '.chakra-select__wrapper + label, ' +
            'textarea:not(:placeholder-shown) ~ label']: {
              ...activeLabelStyles,
            },
            label: floatingLabel,
          },
        },
      },
    },
  },
  colors: {
    primary: '#25368B',
    azulPrimax: '#1E22AA',
    amarilloPrimax: '#FFBB1C',
    orange: {
      500: '#FF6900',
    },
    blue: {
      500: '#263086',
    },
    brand: {
      50: '#FFA20E',
      100: '#FFA20E',
      200: '#FFA20E',
      300: '#FFA20E',
      400: '#FFA20E',
      500: '#FF6900',
      600: '#FF6900',
      700: '#FF6900',
      800: '#FF6900',
      900: '#FF6900',
    },
    grises: {
      400: '#9A9A9A',
      1000: '#3A4256',
    },
    black: {
      400: '#000',
    },
    grisesAzules: {
      100: '#E2E8F0',
      200: '#EDF2F7',
    },
  },
});

export default theme;

import { Box, BoxProps } from '@chakra-ui/react';

export type IconBackgroundProps = BoxProps;

export const IconBackground: React.FC<IconBackgroundProps> = ({ children, padding = '10px', ...props }) => {
  return (
    <Box
      as="div"
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      borderRadius={'50%'}
      padding={padding}
      {...props}
    >
      {children}
    </Box>
  );
};

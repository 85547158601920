import * as React from 'react';
import { getActiveManager } from '@lib/session';
import { AuthenticationTokens, User as GraphQLUser } from '@/gql/__generated__/graphql';
import { jwtDecode } from 'jwt-decode';

export type User = {
  id: string;
  name: string | null;
  lastName: string | null;
  email: string;
  role: string | null;
  verifiedAt: string | null;
  passwordSetAt: string | null;
  profileUpdatedAt: string | null;
};

export interface UserData {
  user: User | null;
  loading: boolean;
}

export const UserContext = React.createContext<UserData>({
  loading: true,
  user: null,
});

export const useUser = () => {
  return React.useContext(UserContext);
};

export interface PartialAuthenticationResult {
  user: Pick<GraphQLUser, 'emailVerified' | 'profileUpdated' | 'email'>;
  tokens: AuthenticationTokens;
}

export const handleAuthenticationResult = (result: PartialAuthenticationResult) => {
  const manager = getActiveManager();

  if (!result.user.emailVerified) {
    console.log('[LOGIN] Account not validated');
    return `?email=${result.user.email ? encodeURIComponent(result.user.email) : ''}`;
  }

  manager.loginWithTokens(result.tokens.access, result.tokens.refresh);

  if (result.user.profileUpdated) {
    console.log('[LOGIN] Account validated and profile updated');
    return '/home';
  }

  console.log('[LOGIN] Pending for updated');
  return '/datos-personales/';
};

export function getUserFromToken(token: string): User | null {
  const decoded = jwtDecode<{
    // Typescript...
    sub: string;
    name: string | null;
    lastName: string | null;
    role: string | null;
    email: string;
    emailVerifiedAt: string | null;
    passwordSetAt: string | null;
    profileUpdatedAt: string | null;
  }>(token);
  if (!decoded) {
    console.error('[JWT] The token was invalid and got an empty value while decoding', { decoded });
    return null;
  }

  return {
    id: decoded.sub,
    name: decoded.name,
    lastName: decoded.lastName,
    role: decoded.role,
    email: decoded.email,
    verifiedAt: decoded.emailVerifiedAt,
    passwordSetAt: decoded.passwordSetAt,
    profileUpdatedAt: decoded.profileUpdatedAt,
  };
}

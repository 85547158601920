import { Text as BaseText, TextProps as BaseTextProps, forwardRef } from '@chakra-ui/react';

export interface TextProps extends BaseTextProps {
  hasBackground?: boolean;
  hasBlock?: boolean;
}

export const Text = forwardRef<TextProps, 'p'>(({ hasBackground = false, hasBlock = false, ...props }, ref) => {
  return (
    <BaseText
      ref={ref}
      color={hasBackground ? '#FF6900' : props.color}
      display={hasBlock ? 'inline-block' : ''}
      fontFamily={'content'}
      {...props}
    />
  );
});

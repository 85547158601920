import { graphql } from '@/gql/__generated__';

export const TOTAL_OPORTUNITIES_QUERY = graphql(`
  query opportunityCount {
    me {
      id
      opportunityCount
    }
  }
`);

export const ME_OPORTUNITIES_QUERY = graphql(`
  query oportunities(
    $input: UserOpportunitiesWhereWhereConditions
    $codes: UserOpportunitiesHasCodeWhereHasConditions
    $first: Int!
    $page: Int
    $orderBy: [UserOpportunitiesOrderByOrderByClause!]
  ) {
    me {
      id
      name
      opportunityCount
      opportunities(where: $input, hasCode: $codes, orderBy: $orderBy, first: $first, page: $page) {
        paginatorInfo {
          count
          total
          hasMorePages
        }
        data {
          id
          code {
            id
            visible
            description
            invoice {
              id
              code
              amount {
                value
                visible
              }
            }
          }
          options
          label
          multiplier
          description
          category {
            id
            name
          }
          status {
            id
            name
            type
          }
          createdAt
        }
      }
    }
  }
`);

export const OPORTUNITYUPDATE_SUBSCRIPTION = graphql(`
  subscription opportunityUpdated($id: ID!) {
    opportunityUpdated(id: $id) {
      id
      code {
        id
        label
      }
      status {
        id
        name
        type
      }
      description
    }
  }
`);

export const EXCHANGE_MUTATION = graphql(`
  mutation exchangeOpportunity($input: ExchangeOpportunityInput!) {
    exchangeOpportunity(input: $input) {
      id
      status {
        id
        name
        type
      }
      won {
        id
        name
        category
        key
      }
    }
  }
`);

export const GETOPPORTUNITIES = graphql(`
  query GetOpportunities(
    $where: QueryOpportunitiesWhereWhereConditions
    $valid: Boolean
    $first: Int!
    $page: Int
    $orderBy: [QueryOpportunitiesOrderByOrderByClause!]
  ) {
    opportunities(where: $where, valid: $valid, orderBy: $orderBy, first: $first, page: $page) {
      paginatorInfo {
        count
        total
      }
    }
  }
`);

export const OPORTUNITYCREATED_SUBSCRIPTION = graphql(`
  subscription OpportunityCreated {
    opportunityCreated {
      total
    }
  }
`);
